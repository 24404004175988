export default {
  props: {
    attributes: {
      type: Object,
      default: () => { return {} }
    },
    extensions: {
      type: Object,
      default: () => { return {} }
    },
    element: {
      type: Object,
      default: () => { return {} }
    }
  },
  methods: {
    onExtensionChangeWithAlias (type, { id, alias }) {
      this.$emit('change-extension', { name: type, value: id })
      this.$emit('change-extension', { name: `alias.${type}`, value: alias })
    },
    async loadRegistryFields (registryId) {
      let data = await this.$http
        .get(`${this.$config.api}/objecteditor/entities?parent_id=${registryId}&show_children=true`)
      return data.data.data
    }
  }
}
